import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as ChevronLeftIconComponent } from '@icons/wolfkit-light/chevron-small-left-light.svg';
import ButtonComponent from '@shared/ui/buttons/ButtonCustom';
const Button = styled(ButtonComponent, { shouldForwardProp: propName => propName !== 'buttonPadding' })(props => ({
    color: props.theme.palette.text.primary,
    border: 'none',
    transition: 'none',
    padding: props.buttonPadding || '3px 16px 3px 8px',
    lineHeight: '30px',
    minHeight: 'unset',
    '&:hover': {
        color: props.theme.palette.text.primary,
        backgroundColor: '#F8F9FA',
        transition: 'none',
        border: 'none',
    },
    '&:active': {
        color: props.theme.palette.text.primary,
        backgroundColor: props.theme.customColors.button.pill.border,
        transition: 'none',
        border: 'none',
    },
    '&:.Mui-disabled': {
        color: props.theme.palette.text.disabled,
        backgroundColor: 'unset',
        cursor: 'none',
    },
}));
const ChevronLeftIcon = styled(ChevronLeftIconComponent, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    path: {
        fill: props.disabled ?
            '#D6DCE0' : props.theme.palette.text.primary,
    },
}));
const BackLinkButton = ({ onClick = undefined, children = '', disabled = undefined, size = undefined, buttonPadding = undefined, }) => (_jsx(Button, { onClick: onClick, startIcon: _jsx(ChevronLeftIcon, { disabled: disabled }), disabled: disabled, size: size, buttonPadding: buttonPadding, children: children }));
export default BackLinkButton;
