var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { forwardRef } from 'react';
const Button = styled(MuiButton)(() => ({
    '& .MuiButton-endIcon>*:nth-of-type(1)': {
        fontSize: 'unset',
    },
}));
const ButtonCustomComponent = (_a, ref) => {
    var { className = undefined, isLoading = false, loaderSize = 20, children = undefined } = _a, props = __rest(_a, ["className", "isLoading", "loaderSize", "children"]);
    return (_jsxs(Button, Object.assign({ className: className, ref: ref }, props, { children: [isLoading && (_jsx(CircularProgress, { color: 'inherit', size: loaderSize })), !isLoading && (children)] })));
};
const ButtonCustom = forwardRef(ButtonCustomComponent);
export default ButtonCustom;
